import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    companyName: 'INTERACT',
    contact: 'Contact Us',
    realFeelAI: 'RealFeel AI <br> See and Feel the Intimacy',
    description: "RealFeel AI uses advanced video recognition to create a responsive and immersive experience that's tailored to you.",
    feature: "Feature",
    watchVideo: "Watch the Video",
    videoType: "Whether local or live video on your phone",
    aiRecognition: "AI Recognition",
    aiRecognitionDescription: "Exclusive AI video algorithms effectively recognize the scene",
    motionTracking: "Motion Tracking",
    motionTrackingDescription: "The adult toy operates automatically based on the recognized imagery",
    coreBenefits: "Core Benefits",
    exclusiveAlgorithm: "Exclusive Algorithm",
    patentedTechnology: "Patented Technology",
    animeVideoSupport: "Anime Video Support",
    appServices: "APP Services",
    supportsMoreToys: "Supports More Toys",
    contactUs: "Contact Us",
  },
  jp: {
    companyName: 'あなたの会社',
    contact: 'お問い合わせ',
    realFeelAI: 'リアルフィールAI <br> 親密さを感じ、見る',
    description: 'リアルフィールAIは、高度なビデオ認識を使用して、あなたに合わせた応答性のある没入型体験を作成します。',
    feature: "特徴",
    watchVideo: "ビデオを見る",
    videoType: "ローカルまたはライブビデオをスマートフォンで",
    aiRecognition: "AI認識",
    aiRecognitionDescription: "独自のAIビデオアルゴリズムがシーンを効果的に認識します",
    motionTracking: "モーショントラッキング",
    motionTrackingDescription: "成人用おもちゃは認識された画像に基づいて自動的に動作します",
    coreBenefits: "コアの利点",
    exclusiveAlgorithm: "独自のアルゴリズム",
    patentedTechnology: "特許技術",
    animeVideoSupport: "アニメビデオサポート",
    appServices: "アプリサービス",
    supportsMoreToys: "より多くのおもちゃをサポート",
    contactUs: "お問い合わせ",
  },
};

const i18n = createI18n({
  locale: 'en', // 默认语言
  messages,
});

export default i18n;
