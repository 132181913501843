<template>
  <div>
    <HomeHeader />
    
    <!-- Introduction Section -->
    <section class="intro-section"> 
      <div class="image-wrapper">
        <div class="text-container"> 
          <h1 class="header-title" v-html="$t('realFeelAI')"></h1>
          <p class="intro-description">{{ $t('description') }}</p>
          <img src="@/assets/images/Frame_6263.png" alt="Item 1" class="log-show" />
          <div class="button-container">
            <a :href="apkUrl" download class="link">
              <button class="custom-button">
                <img src="@/assets/images/android_download.png" alt="Item 1" class="download-icon" />
                <span class="download-title">Android</span>
              </button>
            </a>
            <button class="custom-button disable">
              <img src="@/assets/images/download-ios.png" alt="Item 1" class="download-icon disable" />
              <span class="download-title">iOS coming soon</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- Features Section -->
    <section class="features-section">
      <h2 class="features-title">{{ $t('feature') }}</h2>
      <div class="content-wrapper">
        <div class="wrapper">
          <div class="media-content">
            <div class="media-image small"></div>
            <div class="media-text">&lt; RealFeel AI &gt;</div>
            <div class="media-image large"></div>
          </div>
        </div>

        <div class="feature-details">
          <ul class="features-list">
            <li class="feature-item">
              <img src="@/assets/images/watch_icon.png" alt="Item 1" class="feature-icon" />
              <div class="feature-text">
                <h3 class="feature-title">{{ $t('watchVideo') }}</h3>
                <p class="feature-subtitle">{{ $t('videoType') }}</p>
              </div>
            </li>
            <li class="feature-item">
              <img src="@/assets/images/movie_icon.png" alt="Item 2" class="feature-icon" />
              <div class="feature-text">
                <h3 class="feature-title">{{ $t('aiRecognition') }}</h3>
                <p class="feature-subtitle">{{ $t('aiRecognitionDescription') }}</p>
              </div>
            </li>
            <li class="feature-item">
              <img src="@/assets/images/motions_icon.png" alt="Item 3" class="feature-icon" />
              <div class="feature-text">
                <h3 class="feature-title">{{ $t('motionTracking') }}</h3>
                <p class="feature-subtitle">{{ $t('motionTrackingDescription') }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section class="benefits-section">
      <h1 class="benefits-title">{{ $t('coreBenefits') }}</h1>
      <div class="benefits-top">
        <div class="benefit-item" :style="{ backgroundImage: `url(${require('@/assets/images/Core_Benefits_2.png')})` }">
          <p class="benefit-text">{{ $t('exclusiveAlgorithm') }}</p>
        </div>
        <div class="benefit-item" :style="{ backgroundImage: `url(${require('@/assets/images/Core_Benefits_1.png')})` }">
          <p class="benefit-text">{{ $t('patentedTechnology') }}</p>
        </div>
        <div class="benefit-item" :style="{ backgroundImage: `url(${require('@/assets/images/Core_Benefits_4.png')})` }">
          <p class="benefit-text">{{ $t('animeVideoSupport') }}</p>
        </div>
        <div class="benefit-item" :style="{ backgroundImage: `url(${require('@/assets/images/Core_Benefits_3.png')})` }">
          <p class="benefit-text">{{ $t('appServices') }}</p>
        </div>
        <div class="benefit-item" :style="{ backgroundImage: `url(${require('@/assets/images/Core_Benefits_5.png')})` }">
          <p class="benefit-text">{{ $t('supportsMoreToys') }}</p>
        </div>
      </div>
    </section>


    <section class="contacts-section">
      <div class="contacts-image-wrapper">
        <div class="detailed-info">
          <p class="centered-text">{{ $t('contactUs') }}</p>
          <div class="right-column">
            <div class="detailed-features">
              <div class="detailed-feature-item">
                <img src="@/assets/images/shopping-building--real-home-tower-building-house-estate.png" alt="Feature 1" class="detailed-feature-icon" />
                <p class="detailed-feature-text">
                  Beijing Aikesi Technology Co., Ltd<br>北京爱可私科技有限公司
                </p>        
              </div>
              <div class="detailed-feature-item">
                <img src="@/assets/images/mail-send-envelope--envelope-email-message-unopened-sealed-close.png" alt="Feature 2" class="detailed-feature-icon" />
                <p class="detailed-feature-text">contact@xinteract.cn</p>
              </div>
              <div class="detailed-feature-item">
                <img src="@/assets/images/travel-map-location-pin--navigation-map-maps-pin-gps-location.png" alt="Feature 2" class="detailed-feature-icon" />
                <p class="detailed-feature-text">Block B, Rui Chuang International Center, Chaoyang District, Beijing.<br>
                  北京市朝阳区锐创国际中心B座</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HomeHeader from '@/components/HomeHeader.vue';

export default {
  components: {
    HomeHeader,
  },
  name: 'HomePage',
  data() {
    return {
      apkUrl: '/apk/aikes_v1.apk', // APK 文件的 URL
    };
  },
};
</script>

<style scoped>
/* Global Styles */


.features-section,
.benefits-section,
.contacts-section {
  color: white;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 占满整个视口高度 */
  background-color: #121212; /* 背景颜色为黑色 */

}
.intro-section {
  flex-direction: column; /* 垂直排列子元素 */
  align-items: center; /* 水平居中对齐 */
  justify-content: center; /* 垂直居中对齐 */
  height: 100vh; /* 占满整个视口高度 */
  background-color: #121212; /* 背景颜色为黑色 */
}
.image-wrapper {
  background-image: url('@/assets/images/main_bg.png');
  background-size: cover; /* 使用 cover 以确保背景图覆盖整个区域 */
  background-position: center; /* 背景居中 */
  background-repeat: no-repeat; /* 背景不重复 */
  width: 100%; /* 设置宽度为100% */
  height: 100vh; /* 设置高度 */
  display: flex; /* 使用 Flexbox 布局 */
  flex-direction: column; /* 垂直排列 */
  justify-content: center; /* 垂直居中 */
  align-items: center; /* 水平居中 */
  position: relative; /* 设置为相对定位以使用绝对定位 */
}

.text-container {
  text-align: center; /* 文本居中对齐 */
  position: absolute; /* 绝对定位 */
  bottom: 53px; /* 距离底部 53px */
}

.download-container {
  text-align: center; /* 文本居中对齐 */
  position: absolute; /* 绝对定位 */
  bottom: 53px; /* 距离底部 53px */
}

.header-title {
  font-family: 'Baloo Bhai 2', sans-serif; /* 设置字体 */
  font-size: 52px; /* 字体大小 */
  font-weight: 700; /* 字体粗细，700 表示加粗 */
  line-height: 52px; /* 行高与字体大小相同 */
  text-align: center; /* 文本居中对齐 */
  color: #EEEEEE; /* 字体颜色为白色 */
  margin-bottom: 10px; /* 标题与描述之间的间距 */
}


.intro-description {
  font-size: 18px; /* 描述字体大小 */
  color: rgba(238, 238, 238, 0.8); /* 字体颜色为白色 */
  max-width: 780px; /* 最大宽度限制 */
  text-align: center; /* 文本居中对齐 */
  font-family: 'Baloo Bhai 2';
  font-size: 28px;
  font-weight: 400;
  line-height: 42px;
  text-align: center;
}

.log-show {
  max-width: 144px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row; /* 垂直排列按钮 */
  gap: 12px; /* 按钮之间的间距 */
}

.link {
  text-decoration: none; /* 去掉链接的下划线 */
}

.custom-button {
  background-color: rgba(238, 238, 238, 0.2); /* 设置背景色和透明度 */
  border: none; /* 去掉边框 */
  border-radius: 8px; /* 圆角边框 */
  width: 240px; /* 最大宽度 */
  height: 60px; /* 高度 */
  display: flex; /* 使用 Flexbox 布局 */
  align-items: center; /* 垂直居中内容 */
  justify-content: center; /* 左对齐内容 */
  padding: 0 12px; /* 内边距，确保图标与边缘有间距 */
  cursor: pointer; /* 鼠标悬停样式 */
  font-size: 16px; /* 字体大小 */
  color: #EEEEEE;
}

.custom-button.disable {
  color: rgba(238, 238, 238, 0.2);
}


.download-icon {
  margin-right: 12px; /* 图标与标题之间的间距 */
  width: 28px;
  height: 28px;
}

.download-icon.disable {
  opacity: 0.2; /* 设置半透明效果 */
}

/* Section 2 (Features) */
.features-section {
  background: 
    linear-gradient(122.84deg, rgba(137, 49, 251, 0.1) 10.86%, rgba(200, 54, 255, 0.1) 49.24%, rgba(251, 191, 72, 0.1) 86.86%),
    #121212; /* 黑色背景作为渐变底色 */
    padding: 120;
}

.features-title {
  font-family: 'Baloo Bhai 2';
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


.wrapper {
  display: flex; /* 继续使用 Flexbox 布局以支持内部排列 */
  width: 50%; /* 设置宽度为50% */
  flex-direction: column;
  align-items: flex-end; /* 右对齐 */
  justify-content: center; /* 垂直居中 */

}

.media-content {
  width: 100%; /* 固定宽度或根据内容自适应 */
  height: 100%;
  display: flex; /* 使用 Flexbox 布局 */
  flex-direction: column; /* 垂直方向排列 */
  align-items: center; /* 水平居中内部内容 */
}

.media-image {
  border-radius: 10px; /* 圆角 */
  background-size: contain; /* 确保背景图像完整显示 */
  background-repeat: no-repeat; /* 不重复背景图像 */
  width: 100%; /* 设置为100%以适应父元素 */
  background-position: center; /* 图片内容水平和垂直居中 */

}

.media-image.small {
  background-image: url('@/assets/images/company_name.png'); /* 背景图像 */
  height: 94px; 
  max-height: 94px; /* 最大高度为94px */
  width: 50%; /* 设置为100%以适应父元素 */

}
.media-text {
  font-family: 'Baloo Bhai 2', sans-serif; /* 字体设置 */
  font-size: 36px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  color: white; /* 文案颜色 */
  margin-bottom: -120px;
}
.media-image.large {
  background-image: url('@/assets/images/feature_bg.png'); /* 背景图像 */
  height: 360px; 
  max-height: 360px; 
}


.feature-details {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.features-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feature-item {
  display: flex;
  align-items: center;
  margin: 25px 0;
}

.feature-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}

.feature-text {
  display: flex;
  flex-direction: column;
}

.feature-title {
  font-family: 'Baloo Bhai 2';
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
  text-align: left;
  margin-bottom: 10px; 
}

.feature-subtitle {
  font-family: 'Baloo Bhai 2';
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  margin-top: 0px; 
  color: rgba(238, 238, 238, 0.8); /* 字体颜色为白色 */

}
.benefits-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #121212;

}

.benefits-title {
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
}

.benefits-top {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* 三列布局 */
  grid-template-rows: repeat(2, 1fr); /* 两行布局 */
  gap: 20px;
  width: 75%;
  height: 40%;
}

.benefit-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover; /* 确保背景图覆盖整个区域 */
  background-position: center; /* 背景图居中对齐 */  
  border-radius: 15px;
  padding: 20px;
  text-align: center;  
  max-width: 460px; /* 最大宽度为460像素 */
}

.benefit-item:first-child {
  grid-row: span 2; /* 第一个方块占据第一列的两行 */
}

.benefit-text {
  font-family: 'Baloo Bhai 2';
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: center;
}


.contacts-section {
  background-color: #121212;
  height: 100vh;
}

.contacts-image-wrapper {
  background-image: url('@/assets/images/contacts_bg.png');
  background-size: cover;
  background-position:  bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

}

.centered-text {
  font-family: 'Baloo Bhai 2', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  color: white; /* 添加白色字体颜色以增强对比度 */
  margin-top: 120px;
}

.detailed-info {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.right-column {
  flex: 3;
  display: flex;
  flex-direction: row; /* 改为水平排列 */  
}

.detailed-features {
  display: flex;
  gap: clamp(10px, 5vw, 40px); /* 使用同样的自适应gap */
}

.detailed-feature-item {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: center;
  color: white;
}

.detailed-feature-icon {
  width: 24px;
  height: 24px;
}

.detailed-feature-text {
  font-family: 'Baloo Bhai 2';
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #EEEEEE;
}


@media (max-width: 768px) {
 .features-section,
 .benefits-section,
 .contacts-section {
    height: 100vh;
  }

 .intro-section {
    height: 100vh;
  }

 .image-wrapper {
    background-image: url('@/assets/images/main_bg_mobile.png');
    height: 100vh;
    background-size: cover; /* 使用 cover 以确保背景图覆盖整个区域 */

  }

 .text-container {
    text-align: center; /* 文本居中对齐 */
    position: absolute; /* 绝对定位 */
    width: 100vw;
    bottom: 120px; /* 距离底部 53px */
  }

 .header-title {
    font-family: 'Baloo Bhai 2';
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
    text-align: center;
    
  }

 .intro-description {
    font-family: 'Baloo Bhai 2';
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
  }

 .log-show {
    max-width: 90px;
  }

  .button-container {
    flex-direction: row;
    gap: 10px;
    margin-left: 20px;
    margin-right: 20px;
    justify-content: center;
  }

 .custom-button {
    width: 150px;
    height: 50px;
    font-size: 14px;
    box-sizing: border-box;
  }

 .custom-button.disable {
    color: rgba(238, 238, 238, 0.2);
  }

 .download-icon {
    width: 14px;
    height: 14px;
  }

  .download-icon.disable {
    opacity: 0.2; /* 设置半透明效果 */
  }


  .download-title {
    font-family: 'Baloo 2';
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
  }

  /* Section 2 (Features) */
 .features-section {
    padding: 40px 40px;
  }

 .features-title {
    font-family: 'Baloo Bhai 2';
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  }

 .content-wrapper {
    flex-direction: column;
  }

 .wrapper {
    width: 100%;
    align-items: center;
    flex-direction: column; /* 垂直方向排列 */

  }

 .media-content {
    flex-direction: column; /* 垂直方向排列 */
  }

 .media-image.small {
    height: 47px;
    width: 40%;
  }

 .media-text {
  font-family: 'Baloo Bhai 2';
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  margin-bottom: -78px;
  }

 .media-image.large {
    height: 180px;
  }

 .feature-details {
    width: 100%;
  }

 .feature-item {
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
  }

 .feature-icon {
    width: 30px;
    height: 30px;
  }

 .feature-title {
    font-family: 'Baloo Bhai 2';
    font-size: 16px;
    font-weight: 600;
    line-height: 14px;
    text-align: center;
  }

 .feature-subtitle {
    font-family: 'Baloo Bhai 2';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
  }

 .benefits-section {
    padding: 0px 30px;
  }

 .benefits-title {
  font-family: 'Baloo Bhai 2';
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  }

 .benefits-top {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(5, 1fr);
    gap: 10px;
    width: 100%;
    height: auto;
  }

 .benefit-item {
    max-width: 100%;
    padding: 15px;
  }
  .benefit-item:first-child {
    grid-column: span 2; /* 第一个方块占据第一列的两行 */
  }

  .benefit-text {
    font-family: 'Baloo Bhai 2';
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
  }

 .contacts-image-wrapper {
    background-image: url('@/assets/images/contacts_bg_mobile.png');
    width: 100%;
    height: 100vh;
    background-size: contain;
    background-position:  bottom;
    background-repeat: no-repeat;
  }

 .centered-text {
    font-family: 'Baloo Bhai 2';
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    width: 100%;
    margin-left: 20px;
    margin-top: 63px;
  }

 .detailed-info {
    width: 100%;
  }

 .right-column {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

 .detailed-features {
    flex-direction: column;
    gap: 5px;
  }

 .detailed-feature-icon {
    width: 24px;
    height: 24px;
  }

 .detailed-feature-text {
  font-family: 'Baloo Bhai 2';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-top: 10px;
  }
}


</style>
