<template>
  <div>
    <header :class="{ 
      sticky: isSticky, 
      'has-background': isOffset 
    }" class="header">
      <img src="@/assets/images/log_title.png" alt="Company Logo" class="logo" />
      <div class="language-switch">
        <button
          @click="switchLanguage(false)"
          :style="{ color: isJapanese ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 1)', opacity: isJapanese ? 0.6 : 1 }"
        >
          EN
        </button>
        <button
          @click="switchLanguage(true)"
          :style="{ color: isJapanese ? 'rgba(255, 255, 255, 1)' : 'rgba(238, 238, 238, 0.6)', opacity: isJapanese ? 1 : 0.6 }"
        >
          JP
        </button>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSticky: false,
      isOffset: false, // 新增变量，用于判断是否偏移
      isJapanese: false, // 默认语言为英语
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isSticky = window.scrollY > 0;
      this.isOffset = scrollY > 10; // 可以根据需要调整偏移量
    },
    switchLanguage(isJapanese) {
      this.isJapanese = isJapanese; // 更新语言状态
      this.$i18n.locale = isJapanese ? 'jp' : 'en'; // 切换语言
    },
  },
};
</script>

<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 100px);
  height: 60px;
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s; /* 平滑过渡背景色 */
  background-color: transparent; /* 初始背景色为透明 */
  z-index: 100;
}

.header.sticky.has-background {
  background-color: rgba(18, 18, 18, 0.9); /* 偏移时的背景色 */
}

.logo {
  max-width: 50%;  /* 最大宽度为50% */
  max-height: 25px; /* 最大高度为25px */
  height: auto;     /* 高度自适应 */
  width: auto;      /* 宽度自适应 */
}

.company-name {
  color: white; /* 设置文字颜色为白色 */
  font-size: 24px; /* 设置文字大小 */
  font-weight: bold; /* 设置文字加粗 */
  margin-right: auto; /* 右对齐 */
}

.language-switch {
  display: flex;
  justify-content: center; /* 水平居中 */
}

.language-switch button {
  font-family: 'Baloo Bhai 2', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  background: transparent; /* 背景透明 */
  border: none; /* 无边框 */
  cursor: pointer; /* 鼠标指针为手形 */
  transition: color 0.3s, opacity 0.3s; /* 添加颜色和透明度过渡效果 */
}
@media (max-width: 768px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 40px); /* 减去左右各50px的边距 */
    padding: 20px 20px; /* 上边距10px，左右各50px */
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
  }

  .logo {
    max-width: 50%;  /* 最大宽度为50% */
    max-height: 18px; /* 最大高度为25px */
    height: auto;     /* 高度自适应 */
    width: auto;      /* 宽度自适应 */
  }

  .company-name {
    color: white; /* 设置文字颜色为白色 */
    font-size: 24px; /* 设置文字大小 */
    font-weight: bold; /* 设置文字加粗 */
    margin-right: auto; /* 右对齐 */
  }

  .language-switch {
    display: flex;
    justify-content: center; /* 水平居中 */
  }

  .language-switch button {
    font-family: 'Baloo Bhai 2', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    background: transparent; /* 背景透明 */
    border: none; /* 无边框 */
    cursor: pointer; /* 鼠标指针为手形 */
    transition: color 0.3s, opacity 0.3s; /* 添加颜色和透明度过渡效果 */
  }
}
</style>
