<template>
  <div id="app">
    <Header />
    <router-view />
  </div>
</template>

<script>
import Header from './components/HomeHeader.vue';

export default {
  components: {
    Header,
  },
};
</script>

<style>
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

#app {
  overflow-x: hidden;
}

section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-gradient {
  background: linear-gradient(to right, #ff7e5f, #feb47b);
}

.bg-black {
  background-color: black;
  color: white;
}

.bg-solid {
  background-color: #f0f0f0;
}

.bg-contact {
  background-color: #ececec;
}
</style>
